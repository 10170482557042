<template>
	<div class="moreList" :class="{active:isActive}">
		<div class="list">
			<slot></slot>
		</div>
		<div class="moreBtn" @click="toggle">
			<div v-if="isActive" class="btn">▲折りたたむ</div>
			<div v-else class="btn">▼もっと見る</div>
		</div>
	</div>
</template>

<script>

export default {
	name:'moreList',
	data:function(){
		return {
			isActive:false,
		}
	},
	methods:{
		toggle:function(){
			this.isActive = !this.isActive;
		},
	}
}

</script>

