export default {
	data:function(){
		return {
			isDOMContentLoaded:false,
			isloaded:false,
			mediaClass:'mediaXL',
			mediaLevel:5,
			scrollState:false,
			scrollBorder:200,
		};
	},
	created:function(){
	},
	mounted:function(){
		this.mixinInit();
	},
	methods:{
		mixinInit:function(){
			this.setMediaValue();
			this.setScrollState();
			this.mixinInitAddEvent();
		},
		mixinInitAddEvent:function(){
			window.addEventListener('DOMContentLoaded',this.setDOMContentLoaded,false);
			window.addEventListener('load',this.setLoaded,false);
			window.addEventListener('resize',this.setMediaValue,false);
			window.addEventListener('scroll',this.setScrollState,false);
		},
		setDOMContentLoaded:function(){
			this.isDOMContentLoaded = true;
		},
		setLoaded:function(){
			this.isloaded = true;
		},
		setMediaValue:function(){
			const w = window.innerWidth;
			if(w < 576){
				this.mediaLevel = 1;
				this.mediaClass = 'mediaXS';
			}else if(w < 768) {
				this.mediaLevel = 2;
				this.mediaClass = 'mediaS';
			}else if(w < 992) {
				this.mediaLevel = 3;
				this.mediaClass = 'mediaM';
			}else if(w < 1200) {
				this.mediaLevel = 4;
				this.mediaClass = 'mediaL';
			}else{
				this.mediaLevel = 5;
				this.mediaClass = 'mediaXL';
			}
		},
		setScrollBorder:function(value){
			this.scrollBorder = value
		},
		setScrollState:function(){
			const top = (document.scrollingElement) ? document.scrollingElement.scrollTop : document.documentElement.scrollTop;
			this.scrollState = (top > this.scrollBorder) ? true : false;
		},
	}
}