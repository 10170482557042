<template>
	<div class="imageSlider">
		<swiper :options="swiperOption" ref="imageSlider">
			<swiper-slide v-for="(path,i) in images" :key="i">
				<div class="imgWrap rounded">
					<img :src="'/img/' + path" :alt="'スライド' + (i + 1)">
				</div>
			</swiper-slide>
			<div class="swiper-pagination swiper-pagination-black"  slot="pagination"></div>
			<div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
			<div class="swiper-button-next swiper-button-white" slot="button-next"></div>
		</swiper>
	</div>
</template>


<script>

import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
	name:'imageSlider',
	data:function(){
		return {
			images:[
				'slide_01.png',
				'slide_02.png',
				'slide_03.png',
				'slide_04.png',
				'slide_05.png',
			],
			swiperOption:{
				autoplay:{
					delay:5000,
				},
				speed:500,
				slidesPerView: 3,
				spaceBetween: 10,
				slidesPerGroup: 1,
				loop: true,
				loopAdditionalSlides:2,
				loopedSlides:1,
				loopFillGroupWithBlank:false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
		}
	},
	components:{
		swiper:swiper,
		swiperSlide:swiperSlide,
	},
}



</script>


<style>





</style>