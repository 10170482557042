<template>
	<div :id="id" class="menu-trigger" @click="toggle">
		<div :class="{active:isActive}" class="menu-trigger-lines">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>
</template>


<script>

export default {
	name:'hamburgerMenu',
	data:function(){
		return {
		}
	},
	model:{
		prop:'state',
		event:'change',
	},
	props:['id','state'],
	created:function(){
	},
	methods:{
		toggle:function(){
			this.isActive = !this.isActive;
		},
	},
	computed:{
		isActive:{
			get:function(){
				return this.$props.state;
			},
			set(state){
				this.$emit('change',state);
			}
		}
	}
}

</script>

<style scoped>

.menu-trigger{
	width:60px;
	height:60px;
	z-index:10000;
}
.menu-trigger-lines span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}
.menu-trigger-lines {
  position: relative;
  width: 100%;
  height: 80%;
}
.menu-trigger-lines span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
}
.menu-trigger-lines span:nth-of-type(1) {
  top: 0;
}
.menu-trigger-lines span:nth-of-type(2) {
  top: 44%;
}
.menu-trigger-lines span:nth-of-type(3) {
  bottom: 0;
}

.menu-trigger-lines.active {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.menu-trigger-lines.active span:nth-of-type(1) {
  -webkit-transform: translateY(450%) rotate(-45deg);
  transform: translateY(450%) rotate(-45deg);
}
.menu-trigger-lines.active span:nth-of-type(2) {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}
.menu-trigger-lines.active span:nth-of-type(3) {
  opacity: 0;
}

</style>