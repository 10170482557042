import moment from 'moment';
import 'moment-timezone';
import UAParser from 'ua-parser-js';

const parser = new UAParser();

export default {
	data:function(){
		return {
			commonMethodsTest:'commonMethodsTest_ok',
		};
	},
	created:function(){
		moment.tz.setDefault('Asia/Tokyo');
	},
	methods:{
		isset:function(data){
			if(data === '' || data === null || data === undefined){
				return false;
			}else{
				return true;
			}
		},
		//----------------------------------------
		formatHalfWidth:function(str){
			const reStr = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g,function(s){
				return String.fromCharCode(s.charCodeAt(0) - 65248);
			});
			return reStr;
		},
		formatYear:function(str){
			return this.formatHalfWidth(str).replace(/[^0-9]/g, '');
		},
		formatSendeDate:function(date){
			if(!this.isset(date)) return null;
			const parts = date.split(' ');
			const reg = new RegExp('^[0-9]{2}:[0-9]{2}:[0-9]{2}$');
			if(this.isset(parts[1]) && reg.test(parts[1])){
				return date;
			}else{
				return parts[0] + moment().format(' HH:mm:ss');
			}
		},
		getFormatDate(date){
			if(!this.isset(date)) return null;
			const formated = moment(this.formatDateStr(date)).format('YYYY-MM-DD');
			return (formated === 'Invalid date') ? '' : formated;
		},
		getToday:function(){
			return moment().format('YYYY-MM-DD');
		},
		getYear:function(){
			return moment().format('YYYY');
		},
		getMonth:function(){
			return moment().format('MM');
		},
		getDay:function(){
			return moment().format('DD');
		},
		getNow:function(){
			return moment().format('YYYY-MM-DD HH:mm:ss');
		},
		getDisplayDate:function(formalDate){
			if(!this.isset(formalDate)) return '';
			const formated = moment(this.formatDateStr(formalDate)).format('MM/DD');
			return (formated === 'Invalid date') ? '' : formated;
		},
		getDiffDay:function(date1,date2){
			return moment(this.formatDateStr(date1)).diff(moment(this.formatDateStr(date2)),'days');
		},
		getDateInt:function(date){
			if(!this.isset(date)) return null;
			const formated = moment(this.formatDateStr(date)).format('YYYYMMDDHHmmss');
			return (this.isNumber(+formated)) ? +formated : false;
		},
		getTimeInt:function(date){
			if(!this.isset(date)) return null;
			const formated = moment(this.formatDateStr(date)).format('HHmmss');
			return (this.isNumber(+formated)) ? +formated : false;
		},
		getDayInt:function(date){
			if(!this.isset(date)) return null;
			const formated = moment(this.formatDateStr(date)).format('YYYYMMDD');
			return (this.isNumber(+formated)) ? +formated : false;
		},
		formatDateStr:function(str){
			if(!this.isset(str)) return null;
			const b = parser.getBrowser();
			if(this.isset(b) && this.isset(b.name) && b.name === 'IE'){
				return str.replace('-','/');
			}else{
				return str;
			}
		},
		isNumber:function(val){
			var regex = new RegExp(/^[-+]?[0-9]+(\.[0-9]+)?$/);
			return regex.test(val);
		},
		isValidDate:function(str){
			const d = new Date(this.formatDateStr(str));
			return d.toString() !== 'Invalid Date';
		},
		//----------------------------------------
	}
}