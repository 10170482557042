<template>
	<div v-if="isShow" :id="boxId" class="infoBox flex bgW rounded">
		<div v-if="mediaLevel > 3" class="time flex">
			<slot name="date"></slot>
		</div>
		<div class="title flex">
			<h5><slot name="title"></slot></h5>
		</div>
		<div v-if="mediaLevel < 4" class="time flex">
			<slot name="date"></slot>
		</div>
		<div class="content flex">
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script>

import commonMethods from './commonMethods';

export default {
	name:'newsBox',
	props:[
		'idKey',
		'mediaLevel',
		'from',
		'to',
	],
	mixins:[commonMethods],
	methods:{
		isValidInputDate:function(dateStr){
			if(!this.isset(dateStr)) return false;
			if(!this.isValidDate(dateStr)) return false;
			return true;
		},
	},
	computed:{
		boxId:function(){
			return 'newsBox_' + this.idKey;
		},
		isShow:function(){
			const from = (this.isValidInputDate(this.from)) ? this.getDateInt(this.from) : null;
			const to = (this.isValidInputDate(this.to)) ? this.getDateInt(this.to) : null;
			const now = this.getDateInt(this.getNow());
			if(from && now - from < 0) return false;
			if(to && now - to > 0) return false;
			return true;
		},
	}
}

</script>