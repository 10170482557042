import '@babel/polyfill';
import 'es6-promise/auto';
import 'intersection-observer';
import Vue from 'vue'
import mixin from './components/mixin';
import commonMethods from './components/commonMethods';
import imageSlider from './components/imageSlider.vue';
import intersectionWrap from './components/intersectionWrap.vue';
import resizeHeader from './components/resizeHeader.vue';
import spNav from './components/spNav.vue';
import pageLoading from './components/pageLoading.vue';
import baguetteBox from 'baguettebox.js';
import newsBox from './components/newsBox.vue';
import moreList from './components/moreList.vue';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js';
//--------------------------------------------------------------------
Vue.component('image-slider',imageSlider);
Vue.component('intersection-wrap',intersectionWrap);
Vue.component('resize-header',resizeHeader);
Vue.component('sp-nav',spNav);
Vue.component('page-loading',pageLoading);
Vue.component('news-box',newsBox);
Vue.component('more-list',moreList);
//--------------------------------------------------------------------

const init = () => {

	new Vue({
		el:'#app',
		data:{
			siteName:'医療法人 ただとも胃腸内科・外科',
			version:'1.0.1',
		},
		mixins:[
			mixin,
			commonMethods,
		],
		created:function(){
			this.init();
		},
		mounted:function(){
			baguetteBox.run('.bbGallery');
		},
		methods:{
			init:function(){
				this.setScrollBorder(120);
			}
		},
	});

};

window.addEventListener('DOMContentLoaded',init,false);

//--------------------------------------------------------------------

