<template>
	<div :id="id">
		<hamburger-menu :id="triggerId" v-model="isActive"></hamburger-menu>
		<div class="menu" :class="{active:isActive}">
			<slot name="title"></slot>
			<slot name="menu"></slot>
		</div>
	</div>
</template>

<script>

import hamburgerMenu from './hamburgerMenu.vue';

export default {
	name:'spNav',
	data:function(){
		return {
			isActive:false,
		}
	},
	created:function(){
		if(this.defaultState !== undefined && this.defaultState === true){
			this.isActive = this.defaultState;
		}
	},
	props:['id','triggerId','defaultState'],
	components:{
		hamburgerMenu:hamburgerMenu,
	},
}

</script>
