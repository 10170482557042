<template>
	<transition appear>
		<div v-if="isActive" class="pageLoaderWrap" :style="{backgroundColor:bgColor}">
			<div class="pageLoader"><img :src="imagePath"></div>
		</div>
	</transition>
</template>

<script>
export default {
	name:'pageLoading',
	data:function(){
		return {
			isActive:true,
			isLoaded:false,
		}
	},
	props:['imagePath','bgColor'],
	created:function(){
		this.addEvent();
	},
	methods:{
		addEvent:function(){
			window.addEventListener('load',this.loadedFunc,false);
		},
		loadedFunc:function(){
			this.isLoaded = true;
			setTimeout(() => {
				this.isActive = false;
			},500);
		},
	}
}
</script>

<style scoped>

.v-enter {opacity:1;}
.v-enter .pageLoader{opacity:0;transform:translate(0,50px);}
.v-enter-to .pageLoader{opacity:1;transform:translate(0,0);}
.v-leave {opacity: 1;}
.v-leave-to {opacity: 0;}
.v-leave-to .pageLoader{opacity:0;transform:translate(0,-50px);}

.pageLoaderWrap{display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;width:100%;height:100%;background:#fff;z-index:99999;transition:all 0.5s 0s ease;}
.pageLoader{position:relative;width:100px;transition:all 0.5s 0s ease;-webkit-animation: loading 1.1s infinite ease;animation: loading 1.1s infinite ease;}
.pageLoader img{position:relative;width:100%;}


@-webkit-keyframes loading {
  0%,100% {opacity:1;}
  50% {opacity:0.5;}
}
@keyframes loading {
  0%,100% {opacity:1;}
  50% {opacity:0.5;}
}

</style>